<template>
    <div>
        <dl class="user-info">
            <dt>
                <strong>{{info.nickName}}</strong>
                <em>{{info.mobile}}</em>
            </dt>
            <dd>
                <img :src="info.headimgurl">
            </dd>
        </dl>
        <!--        <van-cell title="我的专属链接" is-link/>-->
        <div class="link-item" @click="showShare = true">
            <em>我的专属链接</em>
            <van-icon name="arrow" color="#a3a3a3" size="20"/>
        </div>
        <div class="link-item" @click="goCollection()">
            <em>收款账号</em>
            <van-icon name="arrow" color="#a3a3a3" size="20"/>
        </div>
        <div class="logout" @click="handleLogout">退出账号</div>
        <!-- 自定义图标 -->
        <van-popup v-model="showShare" position="center" class="share-pop">
            <div class="header-title border-btm">
                <em>我的专属链接</em>
                <van-icon name="cross" color="#6f6f6f" @click="showShare = false"/>
            </div>
            <div class="flex justify-content-space-between align-items-center mt24">
                <em class="url-text">{{ImgUrl}}</em>
                <span
                    data-clipboard-action="copy"
                    class="cobyOrderSn color-fe5e30"
                    :data-clipboard-text="ImgUrl"
                    @click="copyLink"
                >
                    复制地址
                </span>
            </div>
            <img class="code" :src="shareUrl">
            <div class="hint-text">长按保存图片</div>
            <!--<ul>
                <li><i/><em>分享链接</em></li>
                <li @click="funDownload"><b/><i/><em>保存图片</em></li>
            </ul>-->
        </van-popup>
    </div>
</template>

<script>
    import {logout, getUserInfo, getShareImg} from "../../assets/api/user";
    import {removeToken} from "../../utils/cookies";
    import Clipboard from 'clipboard'

    export default {
        data() {
            return {
                info: {},
                showShare: false,
                shareUrl: '',
                ImgUrl: ''
            }
        },
        created() {
            this.getInfo()
        },
        methods: {
            // 获取用户信息
            getInfo() {
                getUserInfo().then(res => {
                    this.info = res.data
                    // 获取分享码
                    let url = '/#/login?distributorCode=' + res.data.distributorCode;
                    this.ImgUrl = res.data.shareUrl
                    getShareImg({url}).then(resImg => {
                        this.shareUrl = resImg.data.url
                    })
                })
            },
            // 收款信息
            goCollection () {
                this.$router.push({name: 'Collection'})
            },
            // 复制
            copyLink() {
                const clipboard = new Clipboard('.cobyOrderSn');
                clipboard.on('success', () => {
                    this.$toast.success('复制成功')
                    // 释放内存
                    clipboard.destroy()
                })
                clipboard.on('error', () => {
                    this.$toast.error('复制失败')
                    clipboard.destroy()
                })
            },
            // 登出
            handleLogout() {
                logout(this.form).then(() => {
                    removeToken()
                    this.$router.push({name: 'Login'})
                })
            },
            // 下载图片
            funDownload() {
                const imgUrl = this.shareUrl

                // // 判断ie浏览器
                if (this.myBrowser() === 'IE' || this.myBrowser() === 'Edge') {
                    this.downLoad(imgUrl)
                    return
                }
                // 解决跨域 Canvas 污染问题
                const image = new Image();
                image.setAttribute('crossOrigin', 'Anonymous')
                image.onload = function () {
                    const canvas = document.createElement('canvas')
                    canvas.width = image.width
                    canvas.height = image.height
                    const context = canvas.getContext('2d')
                    context.drawImage(image, 0, 0, image.width, image.height)
                    const url = canvas.toDataURL('image/png') // 得到图片的base64编码数据
                    const a = document.createElement('a') // 生成一个a元素
                    const event = new MouseEvent('click') // 创建一个单击事件
                    a.download = '分享海报'
                    a.href = url // 将生成的URL设置为a.href属性
                    a.dispatchEvent(event) // 触发a的单击事件
                };

                image.crossOrigin = ''
                image.src = imgUrl
            },
            /**
             * [getBlob 获取二进制流]
             * @param  {[String]}     url     [url]
             * @param  {[Blob]}               [文件二进制]
             */
            getBlob(url) {
                return new Promise(resolve => {
                    const xhr = new XMLHttpRequest();
                    xhr.open('GET', url, true);
                    xhr.responseType = 'blob';
                    xhr.onload = () => {
                        if (xhr.status === 200) {
                            resolve(xhr.response);
                        }
                    };
                    xhr.send();
                });
            },
            /**
             * [saveAs 下载保存文件]
             * @param  {[type]} fileUrl [文件地址]
             */
            saveAs(fileUrl) {
                if (window.navigator.msSaveOrOpenBlob) {
                    // 兼容IE11 发现在微软在ie10 和ie11中有两个特有的方法：window.navigator.msSaveBlob和window.navigator.msSaveOrOpenBlob 方法，
                    // 这两个方法的区别在于，前者只有保存，后者有保存和打开两个选项，按个人喜好使用就行
                    this.getBlob(fileUrl).then(blob => {
                        window.navigator.msSaveBlob(blob, '分享海报.png');
                    });
                }
            },
            downLoad(url) {
                this.saveAs(url);
            },
            myBrowser() {
                var userAgent = navigator.userAgent // 取得浏览器的userAgent字符串
                var isOpera = userAgent.indexOf('OPR') > -1
                if (isOpera) {
                    return 'Opera'
                } // 判断是否Opera浏览器 OPR/43.0.2442.991
                // if (userAgent.indexOf('compatible') > -1 && userAgent.indexOf('MSIE') > -1 && !isOpera) { return 'IE' } // 判断是否IE浏览器
                if (userAgent.indexOf('Firefox') > -1) {
                    return 'FF'
                } // 判断是否Firefox浏览器Firefox/51.0
                if (userAgent.indexOf('Trident') > -1) {
                    return 'IE'
                } // 判断是否IE浏览器  Trident/7.0; rv:11.0
                if (userAgent.indexOf('Edge') > -1) {
                    return 'Edge'
                } // 判断是否Edge浏览器  Edge/14.14393
                if (userAgent.indexOf('Chrome') > -1) {
                    return 'Chrome'
                }// Chrome/56.0.2924.87
                if (userAgent.indexOf('Safari') > -1) {
                    return 'Safari'
                } // 判断是否Safari浏览器 AppleWebKit/534.57.2 Version/5.1.7 Safari/534.57.2
            }
        }
    }
</script>

<style scoped lang="less">
    .user-info {
        height: 227px;
        background-color: #ffffff;
        margin-top: 16px;
        .main-plr;
        .flex;
        .align-items-center;
        .justify-content-space-between;

        strong, em, img {
            display: block;
        }

        strong {
            font-size: 46px;
            color: @color-191b21;
        }

        em {
            margin-top: 24px;
            font-size: @font-size2;
        }

        dd {
            width: 100px;
            height: 100px;
            border-radius: 50%;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                background: #ccc;
            }
        }
    }

    .link-item {
        height: 104px;
        background: #fff;
        .flex;
        .justify-content-space-between;
        .align-items-center;
        .main-plr;
        font-size: @font-size4;
        color: @color-191b21;
        margin-top: 16px;
    }

    .logout {
        height: 104px;
        line-height: 104px;
        background-color: #ffffff;
        .text-center;
        font-size: @font-size4;
        color: @color-191b21;
        margin-top: 16px;
    }

    .share-pop {
        width: 574px;
        padding: 0 32px 38px;
        box-sizing: border-box;
        background-color: #ffffff;
        border-radius: 16px;
        overflow: hidden;

        .van-icon {
            color: #6f6f6f
        }

        .header-title {
            position: relative;
            height: 88px;
            line-height: 88px;
            text-align: center;

            em {
                font-size: 35px;
                color: @color-191b21;
            }

            .van-icon {
                font-size: 20px;
                position: absolute;
                top: 12px;
                right: 0;
            }
        }

        img {
            display: block;
            width: 100%;
            //height: 756px;
            margin: 24px auto 0;
            border-radius: 16px;
            overflow: hidden;
        }

        .hint-text {
            .text-center;
            .color-a3a3a3;
            font-size: @font-size0;
            margin-top: 16px;
        }

        ul {
            .flex;
            .align-items-center;
            margin-top: 16px;
        }

        li {
            position: relative;
            width: 50%;
            .flex;
            .align-items-center;
            .justify-content-center;

            em {
                font-size: @font-size3;
                color: @color-191b21;
                margin-left: 10px;
            }
        }

        li:first-child {
            i {
                width: 72px;
                height: 72px;
                background: url("../../assets/img/icon@2x.png") center no-repeat;
                background-size: 100% 100%;
            }
        }

        li:last-child {
            b {
                position: absolute;
                left: 0;
                top: 11px;
                display: block;
                width: 2px;
                height: 50px;
                background-color: #d4d4d4;
            }

            i {
                width: 72px;
                height: 72px;
                background: url("../../assets/img/iocn34@2x.png") center no-repeat;
                background-size: 100% 100%;
            }
        }
    }

    .mt24 {
        margin-top: 24px;
    }
    .url-text {
        width: 70%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: @color-191b21;
    }
</style>