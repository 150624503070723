import request from '../../utils/request'

// 获取短信验证码
export function sms(data) {
    return request({
        url: '/tool/sms',
        method: 'post',
        data
    })
}

// 登录
export function login(data) {
    return request({
        url: '/login/login',
        method: 'post',
        data
    })
}

// 登出
export function logout(data) {
    return request({
        url: '/login/logout',
        method: 'post',
        data
    })
}

// 用户信息
export function getUserInfo(data) {
    return request({
        url: '/user/info',
        method: 'get',
        params: data
    })
}

// 分享图片
export function getShareImg(data) {
    return request({
        url: '/user/distributor-code',
        method: 'get',
        params: data
    })
}

// 银行信息
export function setBank(data) {
    return request({
        url: '/user/bank',
        method: 'post',
        data
    })
}

// 银行信息
export function getBank(data) {
    return request({
        url: '/user/bank-info',
        method: 'get',
        params: data
    })
}